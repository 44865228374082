import React, { Component } from 'react';
import logo from './logo.jpg';

class Main extends Component {
    componentDidMount() {
        setTimeout(() => {
            this.redirectToFB();
        }, 2050)
    }

    redirectToFB() {
        window.location = 'fb://page/111750674300965';
        this.browserRedirectTimeout('https://www.facebook.com/romana.pipinyte');
    }

    browserRedirectTimeout(url) {
        return setTimeout(function () { window.location = url; }, 25);
    }

    render() {
        return (
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
        );
    }
}

export default Main;
